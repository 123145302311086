import { Formik } from "formik"
import {
  validationSchemaBarMenuItems,
  validationSchemaBeerMenuItems,
  validationSchemaMenuItems,
  validationSchemaWineMenuItems
} from "helpers/validationSchema"
import React, { useEffect } from "react"
import MenuItemsForm from "./components/MenuItemForm"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import {
  addBarMenuItemsAction,
  addMenuItemsActions,
  addWineMenuItemsAction,
  updateBarItemsAction,
  updateFoodItemsAction,
  updateWineItemsAction
} from "redux/marketing/actions"
import BarMenuItemForm from "./components/BarMenuItemForm"
import WineMenuItemForm from "./components/WineMenuItemForm"
import { trackEditItem, trackSaveItem } from "helpers/google_event_trackers"
import moment from "moment"
import BeerMenuItemForm from "./components/BeerMenuItemForm"

const menuText = {
  BARMENU: "bar menu",
  WINEBAR: "wine by glass",
  DINNER: "dinner",
  LUNCH: "lunch"
}

function getMenuDescription(desc) {
  let items = []
  if (!desc) return []
  items = desc?.split(",")
  return items
}

function getAllergence(allergence) {
  let allergenceItems = []
  if (!allergence) return []
  for (let al of allergence) {
    allergenceItems.push(al?.name)
  }
  return allergenceItems
}

function getTestingNotes(testing) {
  let testingItems = []
  if (!testing) return []
  for (let al of testing) {
    testingItems.push(al?.name)
  }
  return testingItems
}

function AddMenuItems({ open = false, onClose = () => {}, isEdit = false }) {
  const { menu_Category_ID, linkCategory, menuItemImageId, foodItem, bar_category } = useSelector(
    (store) => store?.marketing?.menu ?? {}
  )
  const { full_name } = useSelector((store) => store.user?.userData)
  const dispatch = useDispatch()
  const zone_name = moment.tz.guess()

  function initDateConvert(date, time) {
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().toDate()
  }

  const initialValues = {
    dishName: "",
    uploadThumbnail: "",
    menuDescription: [],
    allergens: [],
    chefDescription: "",
    itemPrice: null,
    scheduleNotificationDate: "",
    scheduleNotificationTime: ""
  }

  const wineItemInitialValue = {
    wineName: "",
    producer: "",
    uploadThumbnail: "",
    region: "",
    country: "",
    testingNotes: [],
    varietal: "",
    menuPairing: "",
    soundBites: "",
    itemPrice: null,
    scheduleNotificationDate: "",
    scheduleNotificationTime: ""
  }

  const barItemInitialValue = {
    cocktailName: "",
    uploadThumbnail: "",
    allergens: [],
    ingrediants: [{ name: "", measurement: "" }],
    preparationMethod: "",
    garnish: "",
    glassWare: "",
    bartenderSoundBites: "",
    itemPrice: null,
    scheduleNotificationDate: "",
    scheduleNotificationTime: ""
  }

  const beerItemInitialValue = {
    beerName: "",
    uploadThumbnail: "",
    style: "",
    abv: null,
    color: "",
    brewery: "",
    region: "",
    testingNotes: [],
    itemPrice: null,
    scheduleNotificationDate: "",
    scheduleNotificationTime: ""
  }

  function setInitialFormValue() {
    const notificationTimeStamp = initDateConvert(
      foodItem?.notification_date,
      foodItem?.notification_time
    )
    const notificationTime = moment(notificationTimeStamp).format("HH:mm:ss")
    if (isEdit === false && !foodItem.id) {
      return initialValues
    } else {
      return {
        dishName: foodItem?.name ?? "",
        uploadThumbnail: foodItem?.image
          ? {
              id: "",
              url: foodItem?.image ?? ""
            }
          : "",
        menuDescription: getMenuDescription(foodItem?.menu_description),
        allergens: getAllergence(foodItem?.allergance ?? []),
        chefDescription: foodItem?.description ?? "",
        itemPrice: foodItem?.item_price ?? null,
        scheduleNotificationDate: foodItem?.notification_date || "",
        scheduleNotificationTime: notificationTime || ""
      }
    }
  }

  function setInitialBarFormValue() {
    const notificationTimeStamp = initDateConvert(
      foodItem?.notification_date,
      foodItem?.notification_time
    )
    const notificationTime = moment(notificationTimeStamp).format("HH:mm:ss")
    if (isEdit === false && !foodItem.id) {
      return barItemInitialValue
    } else {
      return {
        cocktailName: foodItem?.name ?? "",
        uploadThumbnail: foodItem?.image_url
          ? {
              id: "",
              url: foodItem?.image_url ?? ""
            }
          : "",
        allergens: getAllergence(foodItem?.allergance ?? []),
        ingrediants: foodItem?.ingredient ?? [{ name: "", measurement: "" }],
        preparationMethod: foodItem?.preparation_method ?? "",
        garnish: foodItem?.garnish ?? "",
        glassWare: foodItem?.glassware ?? "",
        bartenderSoundBites: foodItem?.sound_bite ?? "",
        itemPrice: foodItem?.item_price ?? null,
        scheduleNotificationDate: foodItem?.notification_date || "",
        scheduleNotificationTime: notificationTime || ""
      }
    }
  }

  function setInitialBeerFormValue() {
    const notificationTimeStamp = initDateConvert(
      foodItem?.notification_date,
      foodItem?.notification_time
    )
    const notificationTime = moment(notificationTimeStamp).format("HH:mm:ss")
    if (isEdit === false && !foodItem.id) {
      return beerItemInitialValue
    } else {
      return {
        beerName: foodItem?.name ?? "",
        uploadThumbnail: foodItem?.image_url
          ? {
              id: "",
              url: foodItem?.image_url ?? ""
            }
          : "",
        style: foodItem?.style || "",
        abv: foodItem?.abv || null,
        color: foodItem?.color || "",
        brewery: foodItem?.brewery || "",
        region: foodItem?.region || "",
        testingNotes: getTestingNotes(foodItem?.tasting_notes ?? []),
        itemPrice: foodItem?.item_price ?? null,
        scheduleNotificationDate: foodItem?.notification_date || "",
        scheduleNotificationTime: notificationTime || ""
      }
    }
  }

  function setInitialWineFormValue() {
    const notificationTimeStamp = initDateConvert(
      foodItem?.notification_date,
      foodItem?.notification_time
    )
    const notificationTime = moment(notificationTimeStamp).format("HH:mm:ss")
    if (isEdit === false && !foodItem.id) {
      return wineItemInitialValue
    } else {
      return {
        wineName: foodItem?.name ?? "",
        producer: foodItem?.producer ?? "",
        uploadThumbnail: foodItem?.image_url
          ? {
              id: "",
              url: foodItem?.image_url ?? ""
            }
          : "",
        region: foodItem?.region ?? "",
        country: foodItem?.country ?? "",
        testingNotes: getTestingNotes(foodItem?.tasting_notes ?? []),
        varietal: foodItem?.varitals ?? "",
        menuPairing: foodItem?.menu_pairing ?? "",
        soundBites: foodItem?.sound_bite ?? "",
        itemPrice: foodItem?.item_price ?? null,
        scheduleNotificationDate: foodItem?.notification_date || "",
        scheduleNotificationTime: notificationTime || ""
      }
    }
  }

  useEffect(() => {
    dispatch(marketingActions.setMenuImageId(""))
  }, [])

  function convertDate(date, time) {
    const forDate = moment(new Date(date)).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    return newDate.toUTCString()
  }
  function payloadDate(dateArray = []) {
    return `${dateArray[3]}-${months[dateArray[2]]}-${dateArray[1]}`
  }

  function handleSubmit(e) {
    const NotificationTimeStamp = convertDate(
      e.scheduleNotificationDate,
      e.scheduleNotificationTime
    )
    const NotificationTimeSplit = NotificationTimeStamp.split(" ")
    const payloadStartDate = payloadDate(NotificationTimeSplit)
    const payload = {
      notification_date: payloadStartDate || "",
      notification_time: NotificationTimeSplit[4] || "",
      time_zone: zone_name,
      name: e.dishName,
      meal_period: linkCategory?.item,
      category: menu_Category_ID,
      allergance:
        isEdit === false && !foodItem.id ? e.allergens : e.allergens.flatMap((items) => items),
      description: e.chefDescription,
      menu_description: e.menuDescription,
      item_price: e.itemPrice !== "" ? e.itemPrice : null,
      ...(menuItemImageId ? { image: menuItemImageId } : e.uploadThumbnail ? {} : { image: "" })
    }

    if (isEdit === false && !foodItem.id) {
      dispatch(addMenuItemsActions({ payload: payload, onClose: onClose }))
      trackSaveItem(payload, full_name)
    } else {
      dispatch(updateFoodItemsAction({ payload: payload, onClose: onClose }))
      trackEditItem(payload, full_name)
    }

    // onClose()
  }

  function handleSubmitWineItem(e) {
    const NotificationTimeStamp = convertDate(
      e.scheduleNotificationDate,
      e.scheduleNotificationTime
    )
    const NotificationTimeSplit = NotificationTimeStamp.split(" ")
    const payloadStartDate = payloadDate(NotificationTimeSplit)
    const payload = {
      notification_date: payloadStartDate || "",
      notification_time: NotificationTimeSplit[4] || "",
      time_zone: zone_name,
      name: e.wineName,
      producer: e.producer,
      region: e.region,
      country: e.country,
      varitals: e.varietal,
      menu_pairing: e.menuPairing,
      tasting_notes: e.testingNotes,
      sound_bite: e.soundBites,
      meal_period: "wine glass",
      item_price: e.itemPrice !== "" ? e.itemPrice : null,
      ...(menuItemImageId ? { image: menuItemImageId } : e.uploadThumbnail ? {} : { image: "" })
    }

    if (isEdit === false && !foodItem.id) {
      dispatch(addWineMenuItemsAction({ payload: payload, onClose: onClose }))
      trackSaveItem(payload, full_name)
    } else {
      dispatch(updateWineItemsAction({ payload: payload, onClose: onClose }))
      trackEditItem(payload, full_name)
    }
    // onClose()
  }

  function handleSubmitBarItem(e) {
    const NotificationTimeStamp = convertDate(
      e.scheduleNotificationDate,
      e.scheduleNotificationTime
    )
    const NotificationTimeSplit = NotificationTimeStamp.split(" ")
    const payloadStartDate = payloadDate(NotificationTimeSplit)
    const payload = {
      notification_date: payloadStartDate || "",
      notification_time: NotificationTimeSplit[4] || "",
      time_zone: zone_name,
      name: e.cocktailName,
      ingredient: e.ingrediants,
      meal_period: "bar menu",
      preparation_method: e.preparationMethod,
      garnish: e.garnish,
      glassware: e.glassWare,
      sound_bite: e.bartenderSoundBites,
      allergance: e.allergens,
      bar_category: menu_Category_ID,
      item_price: e.itemPrice !== "" ? e.itemPrice : null,
      ...(menuItemImageId ? { image: menuItemImageId } : e.uploadThumbnail ? {} : { image: "" })
    }
    if (isEdit === false && !foodItem.id) {
      dispatch(addBarMenuItemsAction({ payload: payload, onClose: onClose }))
      trackSaveItem(payload, full_name)
    } else {
      dispatch(updateBarItemsAction({ payload: payload, onClose: onClose }))
      trackEditItem(payload, full_name)
    }
  }

  function handleSubmitBeerItem(e) {
    const NotificationTimeStamp = convertDate(
      e.scheduleNotificationDate,
      e.scheduleNotificationTime
    )
    const NotificationTimeSplit = NotificationTimeStamp.split(" ")
    const payloadStartDate = payloadDate(NotificationTimeSplit)
    const payload = {
      notification_date: payloadStartDate || "",
      notification_time: NotificationTimeSplit[4] || "",
      time_zone: zone_name,
      name: e.beerName,
      style: e.style ?? null,
      meal_period: "bar menu",
      color: e.color ?? null,
      abv: e.abv ?? null,
      brewery: e.brewery ?? "",
      region: e.region ?? "",
      tasting_notes: e.testingNotes,
      bar_category: menu_Category_ID,
      item_price: e.itemPrice !== "" ? e.itemPrice : null,
      ...(menuItemImageId ? { image: menuItemImageId } : e.uploadThumbnail ? {} : { image: "" }),
      allergance: []
    }
    if (isEdit === false && !foodItem.id) {
      dispatch(addBarMenuItemsAction({ payload: payload, onClose: onClose }))
      trackSaveItem(payload, full_name)
    } else {
      dispatch(updateBarItemsAction({ payload: payload, onClose: onClose }))
      trackEditItem(payload, full_name)
    }
  }

  return (
    <>
      {(linkCategory?.item === menuText.LUNCH || linkCategory?.item === menuText.DINNER) && (
        <Formik
          initialValues={setInitialFormValue()}
          enableReinitialize={true}
          validationSchema={validationSchemaMenuItems}
          onSubmit={handleSubmit}>
          {(formik) => (
            <MenuItemsForm
              isEdit={isEdit}
              onClose={onClose}
              formik={formik}
              open={open}></MenuItemsForm>
          )}
        </Formik>
      )}
      {linkCategory?.item === menuText.BARMENU &&
        (bar_category?.toLowerCase() !== "beer" ? (
          <Formik
            initialValues={setInitialBarFormValue()}
            enableReinitialize={true}
            validationSchema={validationSchemaBarMenuItems}
            onSubmit={handleSubmitBarItem}>
            {(formik) => (
              <>
                <BarMenuItemForm
                  isEdit={isEdit}
                  onClose={onClose}
                  formik={formik}
                  open={open}
                  title="Bar Item"></BarMenuItemForm>
              </>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={setInitialBeerFormValue()}
            enableReinitialize={true}
            validationSchema={validationSchemaBeerMenuItems}
            onSubmit={handleSubmitBeerItem}>
            {(formik) => (
              <>
                <BeerMenuItemForm
                  isEdit={isEdit}
                  onClose={onClose}
                  formik={formik}
                  open={open}
                  title="Beer Item"
                />
              </>
            )}
          </Formik>
        ))}
      {linkCategory?.item === menuText.WINEBAR && (
        <Formik
          initialValues={setInitialWineFormValue()}
          enableReinitialize={true}
          validationSchema={validationSchemaWineMenuItems}
          onSubmit={handleSubmitWineItem}>
          {(formik) => (
            <>
              <WineMenuItemForm
                isEdit={isEdit}
                onClose={onClose}
                formik={formik}
                open={open}></WineMenuItemForm>
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default AddMenuItems
const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
}
