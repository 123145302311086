import React from "react"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import DinnerDiningIcon from "@mui/icons-material/DinnerDining"
import "./imageDisplay.scss"

function ImageDisplay({ data }) {
  const theme = useTheme()

  if (!data) {
    return (
      <div className="imageContainer">
        <div>
          <DinnerDiningIcon
            className="mb-4 loadingImage"
            sx={{ color: theme.palette.secondary.main, fontSize: 48 }}></DinnerDiningIcon>
        </div>
        <div>
          <Typography variant="tagItalic">No Image Found</Typography>
        </div>
      </div>
    )
  }
  return <img src={data} alt="" />
}

export default ImageDisplay
