import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { PrivateRoutes, PublicRoutes } from "./routes";
import AppLoader from "components/Loader/AppLoader";
import PublicWrapper from "../hoc/PublicWrapper";
import AuthWrapper from "../hoc/AuthWrapper";
import { useIsLoggedIn } from "hooks/state";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useTheme } from "@mui/material";
import EmailVerify from "pages/email_verify";
import { GmRoutes, Learning_DevelopmentRoutes, MarketingRoute, SuperAdminRoutes } from "./routes/privateRoutes";
import { Roles } from "helpers/role";
import Error404 from "pages/Error404/Error404";


const Router = () => {
  const isLoggedIn = useIsLoggedIn();
  const role = useSelector((res) => res.user?.userData?.role);
  const themeColor = useTheme();

  useEffect(() => {
    document.body.style.backgroundColor = themeColor.palette.background.main;
  }, [role]);

  function setRoleRouting(role) {
    switch (role) {
      case 'gm':
        return GmRoutes.map((route) => (
          <AuthWrapper key={`Route-${route.path}`} {...route} />
        ))
      case 'marketing':
        return MarketingRoute.map((route) => (
          <AuthWrapper key={`Route-${route.path}`} {...route} />
        ))
      case 'learning_development':
        return Learning_DevelopmentRoutes.map((route) => (
          <AuthWrapper key={`Route-${route.path}`} {...route} />
        ))
      case 'super-admin':
        return SuperAdminRoutes.map((route) => (
          <AuthWrapper key={`Route-${route.path}`} {...route} />
        ))
      default:
        return PrivateRoutes.map((route) => (
          <AuthWrapper key={`Route-${route.path}`} {...route} />
        ))
    }
  }
  return (
    <AuthContext.Provider value={isLoggedIn}>
      <Suspense fallback={AppLoader} />
      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to={`/auth/login`} />
          {!isLoggedIn && <Redirect exact from="/" to="/auth/login" />}
          {/* All the public routes */}
          {PublicRoutes.map((route) => (
            <PublicWrapper key={`Route-${route.path}`} {...route} />
          ))}

          {/* All the private routes */}
          { }
          {setRoleRouting(Roles[role])}

          {/* Email Verify */}
          <Route exact path="/verify-email/access-token=:id" component={EmailVerify} />
          {/* 404 page route */}
          <Route exact path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default Router;
