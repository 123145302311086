import React from "react"
import Header from "components/Header/Header"
import { Col, Container, Row } from "react-bootstrap"
import AdminCard from "./components/AdminCard"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { appActions } from "redux/app/slice/app"
import { getDashboardStatsAction, getGamificationDataAction } from "redux/superAdmin/actions"
import { useSelector } from "react-redux"
import TastingNotesConfigCard from "pages/private/DVM_Console/Settings/components/tastingNotesConfigCard"

const frontlineList = {
  bartender_count: "Bartenders",
  server_count: "Servers",
  service_captian_count: "Service Captain",
  sommelier_count: "Sommelier",
  barback_count: "Barback",
  food_runner_count: "Food Runner",
  busser_count: "Busser",
  chef_ed_cusine_count: "Chef De Cuisine",
  president_count: "President & Founder",
  hostess_count: "Hostess",
  executive_chef_count: "Executive Chef",
  polisher_count: "Polisher",
  maitre_count: "Maitre d'",
  executive_pastry_chef_count: "Executive Pastry Chef",
  sonus_chef_count: "Sous Chef",
  total_frontline_users: "Total Frontline Users"
}

const adminList = {
  general_manager_count: "Admin ",
  total_super_admin_count: "Total Admin Users"
}

function SuperAdminDashboard() {
  const dispatch = useDispatch()
  const { dashboardStats } = useSelector((store) => store?.superAdmin || {})
  const { gamificationData } = useSelector((store) => store.superAdmin)

  function getFrontlineStats() {
    if (!dashboardStats?.frontline_data) return
    let frontlineData = []
    Object.keys(frontlineList).forEach((key, index) => {
      frontlineData?.push({
        id: index,
        key: frontlineList[key],
        field: dashboardStats?.frontline_data[key]
      })
    })
    return frontlineData
  }

  function getAdminStats() {
    if (!dashboardStats?.super_admin_data) return
    let AdminStats = []
    Object.keys(adminList).forEach((key, index) => {
      AdminStats?.push({
        id: index,
        key: adminList[key],
        field: dashboardStats?.super_admin_data[key]
      })
    })
    return AdminStats
  }

  useEffect(() => {
    dispatch(getDashboardStatsAction())
    dispatch(getGamificationDataAction())
    dispatch(appActions.initFilters())
    document.title = "Super Admin"
  }, [])
  return (
    <Container fluid className="p-0">
      <Row>
        <Header title="Super Admin Dashboard" back={false}></Header>
      </Row>
      <Row>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/frontline-user-list"
                heading="Frontline Users"
                data={getFrontlineStats() || []}></AdminCard>
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/admin-user-list"
                heading="Admin Users"
                data={getAdminStats() || []}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/notification"
                heading="Notification Engine"
                data={[]}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <TastingNotesConfigCard />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/gift-card-list"
                heading="Monthly & Quarterly Prizes"
                data={[]}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo={
                  gamificationData?.length
                    ? "/super-admin/dashboard/gamification"
                    : "/super-admin/dashboard/gamification/edit-gamification"
                }
                heading="Gamification"
                data={[]}></AdminCard>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard
                navigateTo="/super-admin/dashboard/event-listing"
                heading="Event Management"
                data={[]}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <AdminCard navigateTo={"/super-admin/dashboard/menu-management"} heading="Menu Management" data={[]} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default SuperAdminDashboard

// const adminUsersCard = [
//   {
//     id: 1,
//     key: "Total Admin Users",
//     field: 1000
//   },
//   {
//     id: 2,
//     key: "General Managers ",
//     field: 340
//   },
//   {
//     id: 3,
//     key: "Office Managers",
//     field: 180
//   },
//   {
//     id: 4,
//     key: "Marketing Managers",
//     field: 180
//   },
//   {
//     id: 5,
//     key: "L&D Trainers",
//     field: 180
//   }
// ];

// const frontLineUserCard = [
//   {
//     id: 1,
//     key: "Total Frontline Users",
//     field: 1500
//   },
//   {
//     id: 2,
//     key: "Servers ",
//     field: 340
//   },
//   {
//     id: 3,
//     key: "Bartenders",
//     field: 180
//   },
//   {
//     id: 4,
//     key: "Tasting Room Attendants ",
//     field: 180
//   }
// ];
