import React from "react"
import { Grid, styled, Select, Typography, MenuItem, FormHelperText } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.body1Regular,
  height: 48,
  width: "100%",
  border: `1px solid ${theme.palette.text.secondary} !important`,
  borderRadius: 6,
  padding: "0px",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  backgroundColor: `${theme.palette.primary.main}`,
  "& .MuiSelect-root:before": {
    borderBottom: "none !important"
  },
  "& .MuiSelect-select": {
    paddingLeft: "16px"
  },
  "&:focus": {
    borderRadius: 6,
    borderColor: `${theme.palette.text.primary} !important`
  },
  "& .MuiSvgIcon-root": {
    marginRight: "12px",
    color: theme.palette.text.primary
  },
  "&.Mui-error": {
    borderColor: "#A9402F !important"
  },
  "& .MuiSelect-select.Mui-disabled": {
    color: "#969698 !important",
    WebkitTextFillColor: "#969698"
  }
}))

const StyledMenu = styled(MenuItem)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main} !important`,
  width: "100%",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.hover} !important`
  },
  color: theme.palette.text.primary,
  ...theme.typography.body1Regular
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,

  "& span": {
    color: theme.palette.error.main
  }
}))

const AppSelect = ({
  label,
  isRequired,
  style = {},
  menuItems = [],
  error,
  touched,
  value = "",
  onMenuClick = () => {},
  ...props
}) => {
  return (
    <Grid container style={style}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {label ?? "-"}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <StyledSelect
          IconComponent={KeyboardArrowDownIcon}
          variant="standard"
          disableUnderline={true}
          defaultValue=""
          value={value}
          sx={(theme) => ({
            backgroundColor:
              value && !value?.length == 0 ? theme.palette.background.default : "transparent"
          })}
          error={error && touched && Boolean(error)}
          MenuProps={{
            sx: (theme) => ({
              "& .MuiPaper-root": {
                marginTop: "16px",
                maxHeight: 300,
                backgroundColor: "transparent",
                width: "100px",
                maxWidth: "150%",
                boxShadow: "4px 4px 14px 0px #161616"
              },
              "& .MuiMenu-list": {
                backgroundColor: theme.palette.background.default,
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                width: "100%"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: theme.palette.background.default,
                "&.Mui-selected": {
                  backgroundColor: `${theme.palette.primary.main} !important`
                }
              }
            }),
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          }}
          {...props}>
          {menuItems.map((item, index) => (
            <StyledMenu
              onClick={() => onMenuClick(item)}
              disabled={item.disabled}
              value={item.value}
              key={index.toString()}>
              {item.label}
            </StyledMenu>
          ))}
          {menuItems.length ? null : <StyledMenu disabled>No options available</StyledMenu>}
        </StyledSelect>
      </Grid>
      <FormHelperText error={error || touched}>{error}</FormHelperText>
    </Grid>
  )
}

export default AppSelect
