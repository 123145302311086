import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import {
  Backdrop,
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material"
import styled from "@emotion/styled"
import { useDispatch, useSelector } from "react-redux"
import { formatDate, toTitleCase } from "helpers/functions"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import TableNoRecords from "components/TableNoRecords"
import { Formik } from "formik"
import CreateGiftCard from "./CreateGiftCard"
import AppButton from "components/StyledComponents/AppButton"
import { validationSchemaGiftCard } from "helpers/validationSchema"
import { superAdminAction } from "redux/superAdmin/slice/user"
import {
  EditGiftCardAction,
  addGiftCardAction,
  deleteGiftCardAction,
  getGiftCardListAction,
  getGiftCardsAction
} from "redux/superAdmin/actions/user"
import { appActions } from "redux/app/slice/app"
import AppPagination from "components/StyledComponents/AppPagination"
import moment from "moment"
import Preview from "./GiftCardPreview/preview"

const CustomTable = styled(Table)(() => ({
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))

const GiftCard = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)
  const theme = useTheme()
  const [openModal, setopenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()
  const openMenu = Boolean(anchorEl)
  const rowId = useRef(null)
  const { giftCardList, giftCardData, giftCardImageId } = useSelector(
    (store) => store?.superAdmin?.giftCards || []
  )
  const { card_type, image_url, header, start_date, end_date, start_time } = giftCardData || {}
  const giftCard_type = useSelector((store) => store?.app?.filters?.giftCard_type || {})
  const [currentPage, setCurrentPage] = useState(1)
  const [openPreview, setOpenPreview] = useState(false)
  const initialValues = {
    uploadThumbnail: "",
    giftCardType: "MONTHLY",
    month: moment(new Date()),
    header: "",
    end_date: moment(new Date()),
    time: ""
  }

  const handleDeleteGiftCard = () => {
    dispatch(deleteGiftCardAction(rowId.current))
    rowId.current = null
    dispatch(appActions.setFilters({ giftCard_type: "All" }))
  }
  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen)
  }

  function initDateConvert(date, time) {
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().toDate()
  }

  function convertUTCDate(date, time) {
    const forDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().format("DD MMM YYYY")
  }

  const PrizeTimeStamp = initDateConvert(start_date, start_time)
  const PrizeTime = moment(PrizeTimeStamp).format("HH:mm:ss")

  const initialValuesEdit = {
    uploadThumbnail: image_url
      ? {
          id: "",
          url: image_url ?? ""
        }
      : "",
    giftCardType: card_type || "MONTHLY",
    month: start_date ? moment(initDateConvert(start_date, start_time)) : moment(new Date()),
    header: header || "",
    end_date: moment(end_date) || moment(new Date()),
    time: PrizeTime || ""
  }

  const onOptionsClick = (e, id) => {
    e.stopPropagation()
    rowId.current = id
    setAnchorEl(e.currentTarget)
    dispatch(superAdminAction.setGiftCardID(id))
    dispatch(getGiftCardsAction())
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  function handleAddGiftCard() {
    setIsEdit(false)
    setopenModal(true)
  }

  function handleEditGiftCard() {
    setIsEdit(true)
    setopenModal(true)
  }

  function onClose() {
    setIsEdit(false)
    setopenModal(false)
    dispatch(superAdminAction.setGiftCardID(null))
    dispatch(superAdminAction?.setGiftCardImageId(""))
    dispatch(superAdminAction?.setGiftCardData({}))
  }

  function convertDate(date, time) {
    const forDate = moment(new Date(date)).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    return newDate.toUTCString()
  }

  function payloadDate(dateArray = []) {
    return `${dateArray[3]}-${months[dateArray[2]]}-${dateArray[1]}`
  }

  function handleSubmit(e) {
    var end_date = new Date(e?.end_date)
    var lastDay = end_date

    const PrizeTimeStamp = convertDate(e?.month, e.time)
    const PrizeTimeSplit = PrizeTimeStamp.split(" ")
    const payloadStartDate = payloadDate(PrizeTimeSplit)
    const payload = {
      card_type: e.giftCardType || "MONTHLY",
      header: e.header || "",
      start_date: payloadStartDate || "",
      end_date: formatDate(lastDay),
      ...(giftCardImageId ? { image: giftCardImageId } : e.uploadThumbnail ? {} : { image: "" }),
      status: "SCHEDULED",
      start_time: PrizeTimeSplit[4] || ""
    }
    if (isEdit !== false) {
      dispatch(EditGiftCardAction({ payload: payload, onClose: onClose }))
    } else {
      dispatch(addGiftCardAction({ payload: payload, onClose: onClose }))
    }
    dispatch(appActions.setFilters({ giftCard_type: "All" }))
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Monthly & Quarterly Prize"
    dispatch(getGiftCardListAction({ page: currentPage, giftCard_type: giftCard_type }))
  }, [currentPage, giftCard_type])

  useEffect(() => {
    setCurrentPage(1)
  }, [giftCard_type])

  useEffect(() => {
    dispatch(appActions.initFilters())
    dispatch(appActions.setFilters({ giftCard_type: "All" }))
    dispatch(superAdminAction?.setGiftCardData({}))
  }, [])

  const handlePreviewGiftCard = () => {
    setOpenPreview(!openPreview)
  }

  return (
    <Container fluid className="p-0  ">
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Monthly & Quarterly Prizes"
        onDelete={handleDeleteGiftCard}
        onClose={toggleDeletePopup}
        description="Are you sure you want to delete this Prize?"
      />
      <Grid container>
        <Grid item xs={12}>
          <Header title="Monthly & Quarterly Prizes" breadCrumbs={breadCrumbs}>
            <AppButton onClick={() => handleAddGiftCard()} sx={{ padding: "13px 36px" }}>
              Create Prize
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Row>
        <Col lg="12">
          <TableContainer>
            <CustomTable stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={
                        index === 0
                          ? { minWidth: column.minWidth, paddingLeft: "24px" }
                          : { minWidth: column.minWidth }
                      }>
                      <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {giftCardList?.data?.length ? (
                  <>
                    {giftCardList?.data?.map((row, i) => (
                      <TableRow key={row?.id}>
                        <TableCell align="left" style={{ paddingLeft: "24px" }}>
                          <Typography variant="body2Regular">{i + 1}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2Regular">
                            {row?.card_type ? toTitleCase(row?.card_type) : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="body2Regular">
                            {row?.start_date
                              ? convertUTCDate(row.start_date, row?.start_time)
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                          <span role="button" onClick={(e) => onOptionsClick(e, row.id)}>
                            <MoreHorizIcon
                              sx={() => ({
                                height: "24px",
                                width: "24px"
                              })}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNoRecords colSpan={4} />
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>
          <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              PaperProps={{
                sx: {
                  marginTop: "15px",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "12px",
                  "& .MuiList-root": {
                    backgroundColor: theme.palette.background.default,
                    width: "225px",
                    boxSizing: "border-box",
                    padding: "10px 0"
                  }
                }
              }}>
              <StyledMenuItem onClick={() => handlePreviewGiftCard()}>
                <Typography variant="body1Regular">Preview</Typography>
              </StyledMenuItem>
              <StyledMenuItem onClick={() => handleEditGiftCard()}>
                <Typography variant="body1Regular">Edit</Typography>
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => toggleDeletePopup()}
                sx={{
                  borderTop: `2px solid ${theme.palette.background.secondary}`
                }}>
                <Typography sx={{ color: theme.palette.red.main }} variant="body1Regular">
                  Delete
                </Typography>
              </StyledMenuItem>
            </Menu>
          </Backdrop>
        </Col>
      </Row>
      {giftCardList?.data?.length ? (
        <AppPagination
          onPageChange={setCurrentPage}
          totalPages={giftCardList?.totalPages}
          currentPage={currentPage}
        />
      ) : (
        ""
      )}
      {openModal && (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchemaGiftCard}
          initialValues={isEdit ? initialValuesEdit : initialValues}>
          {(formik) => (
            <CreateGiftCard
              isEdit={isEdit}
              onClose={onClose}
              formik={formik}
              open={openModal}
              giftCardData={giftCardData}
            />
          )}
        </Formik>
      )}
      {openPreview && (
        <Preview open={openPreview} onClose={() => setOpenPreview(false)} data={giftCardData} />
      )}
    </Container>
  )
}

export default GiftCard

const columns = [
  {
    id: "ID",
    label: "ID",
    align: "left",
    minWidth: 50
  },
  {
    id: "Type",
    label: "Type",
    align: "left",
    minWidth: 200
  },
  {
    id: "Month",
    label: "Month",
    align: "left",
    minWidth: 200
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 70
  }
]

const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
}
